@import "../../../styles/utils";

.listHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  white-space: nowrap;
  margin-bottom: 1.2rem;

  .column {
    flex-grow: 1;
    flex-basis: 0;

    max-width: 200px;

    color: $text-tertiary;

    &:first-child {
      max-width: 100px;
    }

    &:nth-child(2) {
      max-width: 60px;
    }

    &:nth-child(4) {
      max-width: 100px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      max-width: 75px
    }
  }

}
