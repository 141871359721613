@import "../../styles/utils";

.menu {
  display: flex;
  align-items: center;

  padding: 1rem;
  background: #FFFFFF;
  border-radius: 2rem;
  //border: .1rem solid rgba(0, 0, 0, 0.1);

  margin: 0 10px;
  width:70%;
  user-select: none;

  p {
    font-size: 1.8rem;
  }
}


.arrow {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 1%;
  user-select: none;

  outline: none !important;
  color: white;
  border: none;
  background-color: $primary;

  &:disabled {
    opacity: 0;
  }
}

.menuCardImage {
  width: 4.4rem;
  height: 4.4rem;
  max-width: 5rem;
  max-height: 4.8rem;

  margin-right: 1.8rem;
  border-radius: 1.5rem;
  background-size: cover;
  background-position: center;
}
