@import "../../../styles/utils";

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.bottom {
  display: flex;
  justify-content: space-between;

  button {
    padding: 0;
  }
}

.title {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.2rem;

  &:first-child {
    margin-right: .4rem;
  }

  svg {
    //width: 1.4rem;
    //height: 1.4rem;
  }
}


.subtitle {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2rem;
  color: $text-secondary;
  margin-bottom: .4rem;
}

.detailsButton {
  svg {
    path {
      stroke: #3B3DBF;
    }
  }
}
