@import "../../styles/utils";

.selectIcons {
  display: flex;
  align-items: center;
}

.preview {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 16rem;
  border-right: 1px solid lightgrey;

  svg {
    transform: scale(2.4);
  }
}

.icons {
  display: flex;
  flex-wrap: wrap;
  flex: 1.8;

  max-height: 20rem;
  overflow: scroll;

  margin-left: .4rem;
}

.icon {
  width: 5.6rem;
  height: 5.6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: .8rem;

  cursor: pointer;

  &:hover, &.selected {
    background-color: rgba($primary, 1);
  }
}
