.orderCard {

}

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.bottom {
  display: flex;

  button {
    flex: 1;
    padding: .8rem 0;
    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
}


.subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.detailsButton {
  svg {
    path {
      stroke: #3B3DBF;
    }
  }
}
