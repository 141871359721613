@import "../../../styles/utils";

.menuRow {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.dragIcon {
  margin-right: 1.4rem;
}

.image {
  width: 5rem;
  height: 4.8rem;
  max-width: 5rem;
  max-height: 4.8rem;

  margin-right: 1.8rem;
  border-radius: 1rem;
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: scale(1.4);
  }
}

.subtitle {
  color: $text-secondary;
  font-size: 1.4rem;
}

.actions {
  display: flex;
  margin-left: auto;

  *:not(:last-child) {
    margin-right: 1rem;
  }
}
