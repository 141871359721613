@import "../../styles/utils";

.confirmationModal {
  width: 60rem;
  height: 13rem;
  outline: 0;
  border-radius: 3rem;
  background-color: white;
  padding: 2rem;
  margin: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include tablet {
    width: 95vw;
  }
}

.confirmationModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  overflow-y:scroll;
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.confirmationModalButtons {
  display: flex;
  margin-top: 1.4rem;

  button {
    flex-basis: 50%;
  }
}
