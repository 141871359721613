@import "../../../styles/utils";

.fileInput {
    display: block;
    width: 100%;
    //height: 6rem;
    outline: none;
    padding: .6rem;
    cursor: pointer;
    border-radius: 1rem;
    border: .1rem solid $gray;

    input {
        width: 1px;
        height: 1px;
        visibility: hidden;
    }

}

.materialTextInput {
    border-radius: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

