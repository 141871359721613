@import "../../styles/utils";

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 26rem;
    overflow: scroll;
    background: #FFFFFF;
    box-shadow: 1rem 0 24.5rem rgba(0, 0, 0, 0.03);
    transition: all linear .2s;

    @include tablet {
        height: 8rem;
        width: 100vw;
        overflow: visible;
        z-index: 1002;
    }
}

.sidebarHeader {
    display: flex;
    justify-content: space-between;
    margin-top: 2.4rem;
    padding-left: 3rem;
    padding-right: 1rem;

    .logo {
        height: 3.2rem;
    }

    @include tablet {
        padding: 2.5rem 2rem 1rem 2rem;
        margin: 0;

        button {
            transform: scale(1.2);
        }
    }
}

.sidebarMenu {
    padding: 0 1rem;
    margin-top: 4.5rem;
    list-style: none;

    @include tablet {
        margin-top: 8rem;
    }
}

.sidebarMenuItem {
    height: 5.6rem;
    display: flex;
}

.sidebarMenuLink {
    width: 100%;
    display: flex;
    align-items: center;
    color: $text-primary;
    padding: 0 2rem;
    border-radius: 1rem;

    span {
        margin-left: 1.2rem;
    }

    svg {
        width: 20px !important;
    }

    &:hover, &.active {
        span {
            color: $primary;
        }
        svg {
            path, line, circle, rect {
                stroke: $primary;
            }
        }
        background-color: rgba($primary, .05);
    }
}

.sidebarMenuHeader {
    width: 100%;
    padding: 0 2rem;
    margin-top: 1rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $text-secondary;
    text-transform: uppercase;
}

.sidebar {
    @include desktop {
        &.closed {
            width: 8rem;
            .sidebarMenuHeader {
                visibility: hidden;
            }

            .sidebarHeader {
                padding: 0;
                flex-direction: column;
                button {
                    margin-top: 1.4rem;
                    margin-left: auto;
                    margin-right: auto;
                }
                .logo {
                    margin: auto;
                    path {
                        display: none;

                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                            display: block;
                        }
                    }
                }
            }

            .sidebarMenu {
                margin-top: 0;
            }

            .sidebarMenuLink {
                padding: 0;
                justify-content: center;
                span {
                    display: none;
                }
            }
        }
    }

    @include tablet {

        .sidebarMenu {
            width: 100vw;
            background-color: white;

            position: absolute;
            left: 0;
            top: 0;
            height: 100vh;
            padding-top: 2rem;
        }

        &.closed {
            overflow: hidden;
        }
    }
}
