@import "../../../styles/utils";

.feedbackRow {
  display: flex;
  padding: 2rem;
}

.image {
  width: 5rem;
  height: 4.8rem;
  max-width: 5rem;
  max-height: 4.8rem;

  margin-right: 1.8rem;
  border-radius: 1rem;
  background-size: cover;
  background-position: center;
}

.subtitle {
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  svg {
    margin-right: .2rem;
    height: 1.4rem;
  }
}

.middleContent {
  margin-left: 5rem;
}

.actions {
  display: flex;
  margin-left: auto;

  *:not(:last-child) {
    margin-right: 1rem;
  }
}
