@import "../../../styles/utils";

#about {
  position: relative;
  min-height: 700px;
  background-color: $primary;

  @media (max-width: 800px) {
    min-height: 650px;
  }

  * {
    color: white;
  }

  .corner-top-left {
    position: absolute;
    top: 40px;
    left: 40px;
  }
  .corner-top-right {
    position: absolute;
    top: 40px;
    right: 40px;
    transform: rotate(90deg);
  }
  .corner-bottom-left {
    position: absolute;
    bottom: 40px;
    left: 40px;
    transform: rotate(270deg);
  }
  .corner-bottom-right {
    position: absolute;
    bottom: 40px;
    right: 40px;
    transform: rotate(180deg);
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    @media (max-width: 800px) {
      top: 44%;
    }

    .title {
      font-size: 40px;
      font-weight: bold;
    }

    .subtitle {
        margin-top: 25px;
      font-size: 17px;

    }
  }


  .arrow-down {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(.7);
    bottom: 15%;

    @media (max-width: 800px) {
      transform: translateX(-50%) scale(.5);
    }
  }


}
