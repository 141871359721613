@import "../../../styles/utils";

.productDetails {
  padding: 0 1.6rem 12rem 1.6rem;
}

.stars {
  margin-left: 1rem;
  margin-top: 1.8rem;
  svg {
    transform: scale(2);
    margin-right: 2rem;
  }
}

.carousel {

  li {
    background: none !important;
  }
}

.sliderImage {
  width: 30rem;
  height: 30rem;
  margin: auto;
  border-radius: 6rem;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.wishlistButton {
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 2.7rem;
  background-color: white;

  svg {
    transform: scale(1.2);
    path {
      stroke: $gray;
    }
  }

  &.active {
    svg {
      path {
        fill: $favorite;
        stroke: $favorite;
      }
    }
  }
}

.ratingButton {
  margin-left: .3rem;
  svg {
    path {
      stroke: $gray;
    }
  }
  transform: scale(1.3);
}
