@import "../../../styles/utils";

.table {
  padding: 1rem;

  cursor: pointer;
  background: #FFFFFF;
  border-radius: 2rem;

  text-align: center;

  &.disabledOrders {
    .tableIcons div {
      width: 100%;
    }
  }
}

.tableIcons {
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bolder;
    font-size: 1.9rem;

    width: 45%;
    height: 4.6rem;
    border-radius: 1.5rem;
    background-color: $background;
  }
}

.tableName {
  margin-top: 1rem;
}

.cartIcon {
  &.active {
    color: $primary;
    background-color: rgba($primary, .2);
  }
}

.bellIcon {
  &.active {
    color: $secondary;
    background-color: rgba($secondary, .2);
  }
}
