.registerSuccess {
  width: 60rem;
  text-align: center;
  margin: auto;

  p {
    font-size: 2.2rem;
    margin-top: 2rem;
    line-height: 3rem;
  }

  svg {
    width: 12rem;
  }
}
