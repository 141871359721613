.stars {
    cursor: pointer;
    display: inline-flex;
    margin-right: 20px;

    svg {
        margin-right: 2px;
        height: 18px;
        width: 18px !important;

        path {
            stroke: #FCB001;
            fill: #FCB001;
        }
    }
}
