@import "../../styles/utils";

.dropdown {
    position: relative;
    width: auto;
    margin-top: 24px;
    border-radius: 10px;
    z-index: 1001;
    cursor: pointer;

    &.opened {
        .dropdown-options {
            max-height: 200px;
            width: 200px;
            transition: all .2s;
        }
    }
}

.dropdown-top {
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        margin-top: 2px;
        margin-right: 8px;
        height: 20px;
        width: 20px;

        rect { fill: $gray }
    }
}

.dropdown-options {
    height: auto;
    width: 190px;
    position: absolute;
    top: 50px;
    right: 0;
    box-shadow: 0px 10px 20px -12px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    max-height: 0;
    transition: all .2s;
}

.dropdown-option {
    font-size: 14px;
    padding: 20px 32px;
    background-color: whitesmoke;

    &:hover {
        background-color: #e5e5e5;
    }

    &.selected {
        background-color: #e5e5e5;
    }
}

.dropdown-placeholder {
    text-transform: uppercase;
    font-size: 1.5rem;
    color: $gray;
    font-weight: bold;
}

