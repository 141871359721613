@import "../../../styles/utils";

#home {

  min-height: 700px;
  position: relative;

  @media (max-width: 1150px) {
    min-height: 600px;

  }

  @media (max-width: 940px) {
    min-height: calc(100vh - 65px)
  }

  * {
    color: $primary;
  }

  .text {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 750px) {
      width: 80%;
      left: 50%;
      top: 20%;
      transform: translate(-50%, -50%);
    }
  }

  .title {
    line-height: 60px;
    font-size: 60px;
    font-weight: bold;

    @media (max-width: 1150px) {
      font-size: 50px;
      line-height: 50px;
    }

    @media (max-width: 940px) {
      font-size: 40px;
      line-height: 40px;
    }

    @media (max-width: 600px) {
      font-size: 35px;
      line-height: 35px;
    }

    @media (max-width: 400px) {
      font-size: 30px;
      line-height: 30px;
    }
  }

  .subtitle {
    font-size: 30px;
    line-height: 35px;
    margin-top: 25px;

    @media (max-width: 1150px) {
      margin-top: 18px;
      font-size: 24px;
      line-height: 28px;
    }

    @media (max-width: 940px) {
      margin-top: 16px;
      font-size: 22px;
      line-height: 24px;
    }

    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 20px;
    }

    @media (max-width: 400px) {
      font-size: 18px;
      line-height: 18px;
    }
  }

  img {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 600px;
    
    @media (max-width: 1150px) {
      height: 500px;
    }

    @media (max-width: 830px) {
      height: 400px;
    }

    @media (max-width: 750px) {
      height: 350px;
    }

    @media (max-width: 560px) {
      height: 300px;
    }
  }


  .scan-button {
    display: none;
    border-radius: 15px;
    background-color: $primary;
    border: none;
    outline: none;
    padding: 6px 20px;
    color: white;
    margin-top: 16px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;

    @media (max-width: 560px) {
      display: block;
    }
  }

}
