@import "../../styles/utils";

.step {
  display: flex;
  align-items: center;
  justify-content: center;

  position: static;
  width: 5rem;
  height: 5rem;

  padding: 0;
  font-size: 2rem;
  color: $primary;
  background: white;
  border-radius: 2.5rem;
  border-color: white;

  svg {
    width: 2rem;
  }
}

.finished {
  background-color: $gray-light
}

.current {
  color: white;
  background-color: rgba($primary, .7);
}
