@import "../../../styles/utils";

.card {
    border-color: $gray;
    position: relative;
    padding: .6rem;
    border-radius: .5rem;

    &.menu-open {
        .card-menu {
            display: block;
        }
    }
}

.card-title {
    color: $primary;
    font-weight: bold;
    margin-top: .2rem;
    margin-bottom: 0;
}

.card-subtitle {
    padding-right: 20px;
    color: $gray;
    font-size: .7rem;
    line-height: 1.2rem;
}

.card-menu-icon {
    z-index: 2;
    position: absolute;
    top: .1rem;
    right: .5rem;
    cursor: pointer;
}

.card-menu {
    display: none;
    position: absolute;
    background-color: white;
    width: 10rem;
    left: 50%;
    transform: translate(-50%);
    top: -4rem;
    border-radius: .5rem;
    border: .1rem solid $gray;
}

.card-time {
    //position: absolute;
    //right: 50px;
    //top: 5px;


    color: $gray;
    font-size: .7rem;
    line-height: 1.2rem;
}

.card-menu-item {
    z-index: 2;
    padding: .3rem;
    text-align: center;

    &:hover {
        background-color: whitesmoke;
    }

    &:first-child {
        border-top-right-radius: .5rem;
        border-top-left-radius: .5rem;
    }

    &:last-child {
        border-bottom-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
    }

    &:not(:last-child) {
        border-bottom: .1rem solid $gray;
    }
}
