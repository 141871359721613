@import "../../../styles/utils";

#info {

  position: relative;
  height: 700px;

  @media (max-width: 800px) {
    //height: 1000px;
    height: auto;
  }

  .content {
    width: 80%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 800px) {
      flex-direction: column;
      width: 100%;
      position: unset;
      align-items: unset;
      transform: unset;
    }

  }

  .title {
    font-size: 50px;
    color: $primary;
    font-weight: bold;
    margin-bottom: 35px;

    @media (max-width: 500px) {
      font-size: 40px;
    }
  }

  .subtitle {
    font-size: 20px;
    color: $primary;
    font-weight: bold;
    margin-top: 35px;
    margin-bottom: 15px;
  }

  .left {
    flex: 5;

    font-size: 16px;
  }

  .right {
    flex: 2;
    max-width: 300px;

    @media (max-width: 800px) {
      padding-top: 40px;
      width: 200px;
      margin: auto;
    }
  }

  .carousel-container {

    position: relative;
    padding: 20px;

    .carousel-root {
      border-radius: 12px;
      -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
    }

    .control-dots {
      display: none;

      li {
        i {
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 2px;
          background-color: $gray;

          -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.8);
          -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.8);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.8);
        }

        display: inline-block;
        margin: 0 8px;
        font-weight: bold;
        color: $gray;
        font-size: 10px;
        cursor: pointer;


        &.active {
          transform: scale(1.5);
          color: $primary;

          i {
            background-color: $primary;
          }
        }
      }
    }

    .carousel .slide {
      background-color: transparent;
    }

    .arrow-next {
      position: absolute;
      top: 50%;
      right: 10px;
      width: 24px;
      cursor: pointer;
      pointer-events: auto;
      transform: translateY(-50%) rotate(135deg);

    }

    .arrow-previous {
      position: absolute;
      z-index: 999;
      top: 50%;
      left: 10px;
      width: 24px;
      cursor: pointer;
      pointer-events: auto;
      transform: translateY(-50%) rotate(-45deg);

    }

    .corner-top-left {
      position: absolute;
      top: -15px;
      left: -15px;
      transform: scale(.8);
    }

    .corner-top-right {
      position: absolute;
      top: -15px;
      right: -15px;
      transform: rotate(90deg) scale(.8);
    }

    .corner-bottom-left {
      position: absolute;
      bottom: -15px;
      left: -15px;
      transform: rotate(270deg) scale(.8);
    }

    .corner-bottom-right {
      position: absolute;
      bottom: -15px;
      right: -15px;
      transform: rotate(180deg) scale(.8);
    }
  }

}
