@import "../../../styles/utils";

.selectInput {
    display: block;
    width: 100%;
    height: 5.8rem;
    outline: none;
    padding: 1.7rem;

    background: #FFFFFF;

    font-size: 1.6rem;
    line-height: 2rem;

    border-radius: 1.5rem;
    border: 1px solid rgba(196, 196, 196, 0.2);
}

.materialSelectInput {
    height: auto;
    padding: .6rem 0;

    border-radius: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
