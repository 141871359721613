@import "../../styles/utils";

.floatingReviewButton {
  display: flex;
  align-items: center;

  position: fixed;
  left: 2rem;
  bottom: 3rem;

  padding: 1rem;

  color: white;
  font-size: 1.4rem;
  line-height: 2.2rem;

  border: none;
  outline: none !important;
  border-radius: 3rem;
  background-color: $primary;

  transform: scale(1.25);

  svg {
    path {
      fill: white;
    }
    line {
      stroke: white;
    }
  }
}
