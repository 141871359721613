@import "../../styles/utils";

.scan-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 70px;
  width: 70px;
  border-radius: 35px;
  outline: none !important;
  border: none;
  background-color: #1e934e;
  box-shadow: 0px 21px 30px rgba(0, 0, 0, 0.23),
              inset 0 0 20px rgba(0, 0, 0, .05);
  display: none;

  svg {
    width: 25px !important;
    height: 25px;
    margin-top: 4px;
  }

  &:active {
    background-color: $primary-dark;
    box-shadow: 0 1px 4px rgba(black,0.6), 0 2px 4px rgba(black,0.6);
  }

  @media (max-width: 750px) {
    display: block;
  }
}

.scan-button-tooltip {
  position: fixed;
  right: 16px;
  bottom: 110px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: black;
  background-color: white;
  padding: 6px 16px;
  border-radius: 6px;
  max-width: 100px;
  text-align: center;
  box-shadow: 0 1px 4px rgba(black,0.1), 0 2px 4px rgba(black,0.1);
  display: none;


  &:after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-40%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid white;
  }

  @media (max-width: 750px) {
    display: block;
  }

}

.landing-page-modal {
  max-width: 700px;
  margin: 35px auto 35px auto;
  background-color: white;
}

.landing-page-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(white, .7);
  overflow: scroll;
}

.close-landing-page-modal-button {
  position: fixed;
  top: 30px;
  right: 30px;
  line-height: 30px;
  font-size: 30px;
  cursor: pointer;
}
