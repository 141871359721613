@import "../../../styles/utils";

.login-box {
    width: 50rem;
    height: 30rem;

    padding: 2.6rem;
    border-radius: .6rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include tablet {
        width: 95vw;
    }
}

.login-logo {
    display: block;
    width: 18rem;
    height: 8rem;
    margin: 0 auto 3rem auto;
    //background-color: $primary;
}
