.help-list {

}

.help-list-item {
  height: 40px;
  font-size: 18px;

  &:hover {
    cursor: pointer;
    color: #1ccd44;
  }
}

.videos-modal {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 4rem;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.videos-model-close-icon {
  position: absolute;
  right: 1.7rem;
  top: 1.7rem;
  cursor: pointer;
  transform: scale(1.3);
}
