@import "../../../styles/utils";

.account-page {
    display: flex;

    @include tablet {
        flex-direction: column;
    }
}

.account-page-sidebar {
    flex: 1.2;
    padding: 10rem 7rem 0 7rem;
    background-color: #fff;
    min-height: 100vh;
    position: relative;

    @include tablet {
        padding: 2rem;
        height: auto;
        min-height: unset;

        .list-menu {
            margin-top: 1rem;
        }
    }
}

.account-page-content {
    flex: 3;
    padding: 10rem 7rem 0 7rem;

    @include tablet {
        padding: 2rem 2rem 7rem 2rem;
        height: auto;
        min-height: unset;
    }
}

.business-logo {
    width: 20rem;
    height: 20rem;
    border-radius: 5rem;
    filter: drop-shadow(0px 40px 40px rgba(0, 0, 0, 0.1));

    margin: auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.logout-button {
    position: absolute;
    bottom: 7rem;
    margin: auto;
    width: calc(100% - 14rem);

    svg {
        path {
            stroke: white;
        }
    }

    @include tablet {
        position: relative;
        bottom: unset;
        width: 100%;
    }
}

.account-page-popup {
    display: flex;
    align-items: center;
    height: 5.2rem;
    color: white;
    z-index: 999;
    background-color: $primary;

    position: fixed;
    left: 50%;
    bottom: -5.3rem;
    transform: translateX(-50%);

    padding: 0 2rem;
    border-radius: 4rem;

    transition: all .2s linear;

    svg {
        margin-right: 1rem;
        circle, path {
            stroke: white;
        }
    }

    &.active {
        bottom: 2.4rem;
    }

    @include tablet {
        width: 95vw;
    }
}
