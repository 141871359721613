@import "../../styles/utils";

.list {

}

.list-item {
    margin-bottom: 1rem;
    background: #FFFFFF;
    border: .1rem solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 2rem;
}

.list-item-title {
    font-weight: bold;
    margin-left: 12px;
}

.list-item-image {
    margin-right: 1rem;
    max-height: 3rem;
    border-radius: .6rem;
}

.list-item-image-wrapper {
    width: 80px;
    overflow: hidden;
    text-align: center;
    border-radius: .6rem;
}

.list-item-buttons {
    margin-left: auto;

    button:not(:last-child) {
        margin-right: .8rem;
    }
}
