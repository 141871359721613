@import "../../../styles/utils";

.searchContainer {
  padding: 1.6rem;

  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: $background;
}

.logo {
  display: block;
  width: 18rem;
  height: 8rem;
  margin: 0 auto 2rem auto;
}

.businessesContainer {
  margin-top: 2rem;
}
