@import "../../../styles/utils";

.register-box {
    width: 75rem;
    height: auto;

    padding: 2.6rem;
    border-radius: .6rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.with-map {
        left: 35%;
    }

    @include tablet {
        width: 95vw;
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
        margin: 5rem auto;
        padding-bottom: 5rem;
    }
}

.login-logo {
    display: block;
    width: 18rem;
    height: 8rem;
    margin: 0 auto 3rem auto;
    //background-color: $primary;
}

.steps-line {
    height: .2rem;
    width: 4rem;
    background-color: rgba($primary, .3);
}

.maps-drawer {
    height: 100vh;
    position: absolute;
    right: 0;
    .drawer-content {
        padding: 0;
    }
}
