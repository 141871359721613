@import "../../../styles/utils";

.productCard {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 2rem;
    background-color: white;

    button {
        margin-left: auto;

        svg {
            transform: scale(.8);

        }
    }
}

.productCardImage {
    width: 5rem;
    height: 4.8rem;
    max-width: 5rem;
    max-height: 4.8rem;

    margin-right: 1.8rem;
    border-radius: 1.5rem;
    background-size: cover;
    background-position: center;
}

.productCardSubtitle {
    color: $primary;
    font-size: 1.65rem;
    line-height: 2.1rem;
}

.activePlusButton {
    background-color: rgba($primary, .1);

    svg {
        path {
            stroke: $primary;
        }
    }
}

.activeWishlistButton {
    background-color: rgba($favorite, .1);

    svg {
        path {
            stroke: $favorite;
            fill: $favorite;
        }
    }
}

.productCardTitle {
    font-size: 1.7rem;
    line-height: 2.2rem;
}

.grid {
    display: unset;
    position: relative;

    .productCardTitle {
        height: 4.5rem;
        margin-bottom: 0.6rem;
    }

    .productCardImage {
        width: 100%;
        height: 7.8rem;
        max-width: unset;
        max-height: unset;
        margin-bottom: 0.5rem;
    }

    .heartButton {
        position: absolute;
        bottom: .4rem;
        right: .5rem;
        //background-color: transparent;
        border-color: transparent;
        transform: scale(0.9);
    }

    .plusButton {
        position: absolute;
        bottom: .4rem;
        right: .5rem;
        border-color: transparent;
        transform: scale(0.85);
    }

}



