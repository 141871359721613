@import "../../styles/utils";

.clientContentWrapper {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: $background;
}

.clientContent {
    //padding-right: 1.6rem;
    //padding-left: 1.6rem;
}

.slidingPane {
    //overflow: hidden;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}

.slidingPaneClosButton {
    position: fixed;
    top: calc(-5.5rem - 1rem);
    right: 1rem;

    width: 5.5rem;
    height: 5.5rem;
    border-radius: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(black, .5);

    svg {
        transform: scale(1.2);
        path {
            stroke: white;
        }
    }
}

.disabledClientContent {
    overflow: hidden;
    pointer-events: none;

    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);

    transition: filter .2s linear .3s;
}
