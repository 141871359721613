@import "../../styles/utils";

.submenusSliderItem {
  //display: flex;
  //flex-direction: column;
  border-radius: 3.5rem;
  padding: 1rem 2rem;
  margin-right: 1.2rem;
  background-color: white;
  white-space: nowrap;

  svg {
    margin-right: 1rem;
    //margin: auto;
    ////max-width: 2.8rem;
    //max-height: 2.8rem;
    //transform: scale(1.2);
  }

  &.selected {
    color: white;
    background-color: $primary;

    &:not(.onlyIcon) {
      svg {
        path {
          stroke: white;
        }
      }
    }
  }

  &.onlyIcon {
    svg {
      margin-right: 0;
    }
    span {
      display: none;
    }
  }
}

.submenusSliderItemTitle {
  font-size: 1.7rem;
}
