@import "../../../styles/utils";

#subscribe {
  padding: 60px;
  background-color: white;

  @media (max-width: 600px) {
    padding: 40px;
  }

  .title {
    color: $primary;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 40px;

    @media (max-width: 600px) {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }

  .subscribe-wrapper {
    width: 400px;
    height: 50px;
    border-radius: 25px;
    padding: 3px;
    background-color: white;
    margin: auto;
    border: 2px solid $primary;



    display: flex;
    justify-content: stretch;

    @media (max-width: 600px) {
      width: 90%;
    }

    @media (max-width: 500px) {
      width: 100%;
    }

    input {
      display: block;
      border: 0;
      width: 100%;
      border-radius: 20px;
      padding: 0 20px;
      outline: 0;
      color: black;
    }

    button {
      padding: 0 20px;
      border-radius: 25px;
      border: 0;
      color: white;
      font-weight: bold;
      font-size: 14px;
      outline: 0;
      background-color: $primary;

      &:active {
        background-color: $primary-dark;
      }
    }


  }
}
