@import "../../../styles/utils";

.footer {
  //display: flex;
  color: white;
  //justify-content: space-between;
  background-color: $primary;
  padding: 30px 20%;
}

.social {
  order: -1;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
    width: 30px;
    height: 30px;
  }
}

.privacy-policies-text {
  cursor: pointer;
}

@media only screen and (max-width: 740px) {
  .footer {
    flex-direction: column;
  }

  .social {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

  }
}

