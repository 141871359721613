@import "../../../styles/utils";

.menuCard {
    display: flex;
    align-items: center;
    padding: 3rem 1rem;
    border-radius: 2rem;
    background-color: white;

    button {
        margin-left: auto;
    }
}

.menuCardImage {
    width: 5.5rem;
    height: 5.2rem;
    max-width: 5rem;
    max-height: 4.8rem;

    margin-right: 1.8rem;
    border-radius: 1.5rem;
    background-size: cover;
    background-position: center;
}

.menuCardSubtitle {
    color: $text-tertiary;
    font-size: 1.4rem;
    line-height: 2.1rem;
}

.menuCardTitle {
    font-size: 2.1rem;
}




