@import "../../styles/utils";

.productImages {
  display: flex;
}

.image {
  position: relative;
  width: 6.5rem;
  height: 6.5rem;

  border-radius: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 1.2rem;
}

.addNewButton {
  position: relative;
  width: 6.5rem;
  height: 6.5rem;

  cursor: pointer;
  border-radius: 2rem;
  border: .1rem solid rgba($primary, .2);
  background-color: rgba($primary, .05);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    path {
      stroke: $primary;
    }
  }
}

.removeButton {
  width: 2.4rem;
  height: 2.4rem;
  padding: .1rem;
  border-radius: 1.2rem;
  background-color: $red;

  cursor: pointer;
  color: white;
  text-align: center;

  position: absolute;
  top: -1.2rem;
  right: -1.2rem;
}
