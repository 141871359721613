.star {
    fill: url(#halfGradient2) !important;
    stroke: #B0813B;
    stroke-width: 2;
}
.star-half {
    stroke: #B0813B;
    stroke-width: 2;
        fill: url(#halfGradient) !important;
}

.star-empty {
    fill: none !important;
    stroke: #B0813B;
    stroke-width: 2;
}


