@import "../../styles/utils";

.dots {
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.dot {
  position: relative;
  width: 2rem;
  height: 2rem;

  &:before {
    content: "";
    width: .8rem;
    height: .8rem;
    border-radius: .4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $gray;
    transform: translate(-50%, -50%);
  }

  &.selected {
    &:before {
      background-color: $primary;
    }
  }
}
