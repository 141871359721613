@import "../../styles/utils";

.status {
  display: inline-flex;
  align-items: center;

  color: white;
  font-size: 1.4rem;

  width: auto;
  height: 3.5rem;
  padding: 0 .8rem;
  border-radius: 3rem;

  &.yellow {
    color: $yellow;
    background-color: rgba($yellow, .05);

    svg {
      margin-right: .4rem;
      path, circle {
        stroke: $yellow;
      }
    }
  }

  &.red {
    color: $red;
    background-color: rgba($red, .05);

    svg {
      margin-right: .4rem;
      path, circle {
        stroke: $red;
      }
    }
  }

  &.green {
    color: $primary;
    background-color: rgba($primary, .05);

    svg {
      margin-right: .4rem;
      path, circle {
        stroke: $primary;;
      }
    }
  }
}
