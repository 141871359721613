@import "../../styles/utils";

.inputGroup {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;

    input, textarea {
        color: rgba(0, 0, 0, 0.85);
    }
}

.validationMessage {
    position: absolute;
    bottom: -1.7rem;
    left: 1rem;

    color: red;
    font-size: 1.2rem;
}

.inputLabel {
    color: $text-secondary;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: -0.24px;
}

.materialInput {
    margin-bottom: 2.2rem;

    .inputLabel {
        font-size: 1.4rem;
    }

    .validationMessage {
        bottom: -2.1rem;
        left: 0;
    }
}
