@import "../../styles/utils";

.floatingButtons {
  position: fixed;
  right: 2rem;
  bottom: 2rem;

  display: flex;
  align-items: center;
}

.callWaiterButton {
  width: 7.2rem;
  height: 7.2rem;
  outline: none !important;
  border: none;
  border-radius: 3.6rem;
  background-color: $secondary;
  transition: all .1s linear;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2),
        inset 0 0 34px rgba(0, 0, 0, 0.1);


  color: white;
  font-weight: bold;
  font-size: 1.8rem;


  &.disabled {
    pointer-events: none;
  }

  &.pop {
    transform: scale(1.2);
  }

  svg {
    width: 2.6rem;
    height: 2.6rem;
    path {
      stroke: white;
    }
  }
}

.wishlistButton {
  position: relative;
  width: 7.2rem;
  height: 7.2rem;
  margin-left: 1.5rem;
  outline: none !important;
  border: none;
  border-radius: 3.6rem;
  background-color: $primary;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.4),
        inset 0 0 34px rgba(0, 0, 0, 0.3);

  .wishlistButtonCount {
    position: absolute;
    top: -.3rem;
    right: -.3rem;
    width: 2.2rem;
    height: 2.2rem;
    background-color: white;
    border-radius: 1.1rem;
    color: $primary;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2.2rem;
  }

  &.wishlist {
    background-color: $favorite;

    .wishlistButtonCount {
      color: $favorite;
    }
  }

  svg {
    width: 2.6rem;
    height: 2.6rem;
    path {
      stroke: white;
    }
  }
}
