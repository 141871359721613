@import "../../../styles/utils";

.productsContainer {
  margin-top: 1rem;
  //overflow: scroll;
  //max-height: calc(100vh - 8rem - 7.2rem - 7rem);
  padding: 0 1.6rem 12rem 1.6rem;
}

.submenusSlider {
  position: relative;
  display: flex;
  height: 7.2rem;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  padding-right: 30px;
}

.submenusSliderItem {
  display: flex;
  flex-direction: column;
  border-radius: 3.5rem;
  padding: 1rem 2rem;
  margin-right: 1.2rem;
  background-color: white;

  svg {
    margin: auto;
    transform: scale(1.2);
  }

  &.selected {
    color: white;
    background-color: $primary;

    svg {
      path {
        stroke: white;
      }
    }
  }
}

.submenusSliderItemTitle {
  font-size: 1.4rem;
}

.submenuTitle {
  font-size: 1.9rem;
  font-weight: bold;
  margin-bottom: .8rem;
}

.menusSlider {
  //width: calc(100% + 20px) !important;
  //margin: 0 -10px 1.4rem -10px;
  margin-bottom: 1.6rem;
  background-color: transparent !important;


  button {
    opacity: 1 !important;
  }
}

.submenusGrid {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  gap: 0 2%;
  grid-auto-rows: minmax(50px, auto);
}

.submenusViewSelect {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 1.5rem 1.5rem 1rem 0;
  font-size: 1.8rem;

  svg {
    margin: 0 1rem;
    transform: scale(1.2);

    &.selectedView {
      path {
        stroke: $primary;
      }
      circle {
        fill: $primary;
      }
    }
  }
}

.scrollMenuWrapper {
  scroll-behavior: auto;
}
