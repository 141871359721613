@import "../../styles/utils";

.quantity {
  display: flex;
  align-items: center;

  .quantityNumber {
    color: $text-tertiary;
    margin: 1.5rem;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 4.2rem;
  }

  button {
    width: 5.4rem;
    height: 5.4rem;
    border-radius: 2.7rem;
    background-color: white;

    svg {
      transform: scale(1.4);
      path {
        stroke: $gray;
      }
    }
  }

  &.inverse {
    button {
      background-color: $background;
    }
  }

  &.small {
    button {
      width: 3.6rem;
      height: 3.6rem;

      svg {
        transform: unset;
      }
    }

    .quantityNumber {
      font-size: 1.8rem;
    }
  }
}
