@import "../../../styles/utils";

.timeInput {
    display: block;
    width: 100%;
    height: 5.8rem;
    outline: none !important;
    padding: 1.7rem;

    background: #FFFFFF;

    font-size: 1.6rem !important;
    line-height: 2rem;

    border-radius: 1.5rem !important;
    border: 1px solid rgba(196, 196, 196, 0.2) !important
}

.materialTimeInput {
    height: auto;
    padding: .6rem 0;

    border-radius: 0  !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
}
