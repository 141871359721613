.product-images {
    display: flex;
    margin-bottom: 20px;

    .product-add-image-button {
        position: relative;
        width: 70px;
        height: 70px;
        cursor: pointer;
        border-radius: 4px;
        background-color: rgb(232, 232, 232);

        &:hover {
            background-color: rgb(217, 217, 217);
        }

        svg {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .product-image-box {
        display: flex;
        position: relative;
        width: 70px;
        height: 70px;
        border-radius: 4px;
        align-items: center;
        margin-right: 6px;
        background-color: rgb(232, 232, 232);

        .product-image {
            display: block;
            margin: auto;
            width: auto;
            height: auto;
            max-width: 70px;
            max-height: 70px;
        }

        .delete-image-button {
            position: absolute;
            top: -6px;
            right: -7px;
            width: 18px;
            height: 18px;
            cursor: pointer;
            text-align: center;
            border-radius: 9px;
            background-color: #b40000;
            border: 2px solid #FFFFFF;

            &:hover {
                background-color: darkred;
            }

            svg {
                width: 8px;
                height: 8px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}












