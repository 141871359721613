@import "../../../styles/utils";

.bars {
    color: $text-tertiary;

    .bar-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .bar-level {
            flex: 2.5;
            display: flex;
            align-items: center;

            svg {
                margin-left: 4px;
                height: 16px;
            }
        }

        .bar-info {
            flex: 2;
            line-height: 30px;
            font-size: 13px;
            text-align: right;
        }

        .bar-progress {
            flex: 11.5;
            height: 12px;
            overflow: hidden;
            border-radius: 12px;
            background-color: rgba(69, 74, 103, 0.1);

            .bar {

                * {
                    height: 12px;
                    border-radius: 12px;
                    background: #FCB001
                }
            }
        }
    }
}
