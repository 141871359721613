@import "../../../styles/utils";

.daily-menu-button {
  margin-top: 10px;
  //float: right;
  color: white;
  border: none;
  outline: none;
  padding: 6px 40px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 18px;
  background-color: #FEAE11;

  &:active {
    background-color: #c88d13;
  }
}

.wishlistButton {
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 2.7rem;
  margin-top: 2rem;

  svg {
    transform: scale(1.2);
    path {
      stroke: $gray;
    }
  }

  &.active {
    background-color: rgba($favorite, .1);

    svg {
      path {
        fill: $favorite;
        stroke: $favorite;
      }
    }
  }
}
