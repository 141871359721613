@import "../../../styles/utils";

.tables {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-gap: 2rem;
  align-items: stretch;
}


.mini-tables {
  max-width: 300px;
  height: auto;
  position: fixed;
  bottom: 0;
  right: 100px;
  padding: 20px;
  padding-bottom: 30px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20px, 1fr));
  grid-gap: 5px;
  align-items: stretch;

  transition: right .2s linear;
  background-color: rgba(white, .95);

  box-shadow: 0 0px 28px rgba(0, 0, 0, 0.20), 0 10px 10px rgba(0, 0, 0, 0.13);

  .mini-table {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid $gray;

    &.active-calls {
      border-color: rgba($secondary, .6);
      background-color: rgba($secondary, .6);
    }

    &.active-orders {
      border-color: rgba($primary, .6);
      background-color: rgba($primary, .6);;
    }
  }
}

.wrapper.ant-scrolling-effect {
    .mini-tables {
      right: calc(50px + 44rem);
    }
}

