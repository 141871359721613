.eventModalImage {
  width: 30rem;
  height: 30rem;
  margin: auto;
  border-radius: 6rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 4rem 4rem rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
