@import "../../../styles/utils";

.switchInput {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 5.8rem;
    outline: none;
    padding: 1.7rem;

    background: #FFFFFF;

    font-size: 1.6rem;
    line-height: 2rem;

    cursor: pointer;
    border-radius: 1.5rem;
    border: 1px solid rgba(196, 196, 196, 0.2);
}

.switch{
    display: flex;
    align-items: center;
    padding: 0 .3rem;
    width: 5rem;
    height: 3rem;
    position: relative;
    border-radius: 1.5rem;
    border: .2rem solid $primary;
    transition: all linear .2s;

    .switchCircle {
        height: 20px;
        width: 20px;
        background-color: $primary;
        border-radius: 10px;
        transition: all linear .2s;
    }

    &.active {
        justify-content: flex-end;
    }
}

