@import "../../../styles/utils";

.wishlistModal {
  margin-top: -1.2rem;
}

.wishlistModalTabs {
  display: flex;
  margin-bottom: 2.4rem;
}

.wishlistModalTab {
  flex-basis: 50%;
  text-align: center;
  font-size: 1.8rem;
  line-height: 3rem;

  &.active {
    color: $primary;
  }
}
