@import "../../styles/utils";

.drawerWrapper {
}

.drawer {
  width: 0;
  height: 100%;
  overflow: scroll;
  background-color: white;
  transition: width .2s linear;

  &.drawer-open {
    width: 44rem;
    z-index: 1003;

    @include tablet {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      padding-bottom: 12rem;
      background-color: white;
    }
  }
}

.drawer-content {
  padding: 3rem;
}
