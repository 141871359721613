@import "bootstrap.css";
@import "utils";
@import "fonts.css";

* {
    scroll-behavior: smooth;
}

html {
    height: 100%;
    font-size: 9px;
    //overflow: hidden;
}

body {
    height: 100%;
    scroll-behavior: unset !important;
    font-size: 14px;
    color: $text-primary !important;
    background-color: $background !important;
    font-family: Poppins, arial, serif !important;

    &.sidebar-closed {
        .content-wrapper {
            margin-left: 8rem;
            transition: all linear .2s;

            @include tablet {
                margin-left: 0;
            }
        }
    }

    &.ReactModal__Body--open {
        overflow: hidden;

        #root {
            filter: blur(.3rem);
        }
    }

    &:not(.client) {
        @include tablet {
            margin-top: 8rem;
        }
    }
}

#root {
    height: 100%;
    transition: .5s filter linear;
    -webkit-transition: .2s -webkit-filter linear;
    -moz-transition: .5s -moz-filter linear;
    -ms-transition: .5s -ms-filter linear;
    -o-transition: .5s -o-filter linear;
}

a {
    color: $text-secondary !important;
    text-decoration: none !important;

    &:hover {
        color: $text-primary !important;
    }
    &:active {
        color: $text-primary !important;
    }
}

p {
    margin: 0;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 2.4rem;
}

h1 { @include heading(3.4rem, 4.2rem); }
h2 { @include heading(2.4rem, 3.2rem); }
h3 { @include heading(2.0rem, 2.8rem); }
h4 { @include heading(1.8rem, 2.6rem); }
h5 { @include heading(1.6rem, 2.4rem); }
h6 { @include heading(1.4rem, 2.0rem); }

.wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: stretch;

    @include tablet {
        height: auto;
    }
}

.content-wrapper {
    flex: 1;
    overflow: scroll;
    position: relative;
    margin-left: 26rem;
    transition: all linear .2s;

    @include tablet {
        margin-left: 0;
    }
}

.content {
    padding: 10rem 7rem 0 7rem;

    &.waiter-content {
        overflow-y: auto;
        padding-bottom: 235px;
    }

    @include tablet {
        padding: 2.4rem 1.2rem 5rem 1.2rem;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
}

.slide-pane__content {
    padding: 3rem 2rem 10rem 2rem !important;
}

.slide-pane_from_bottom {
    height: 91.5vh !important;
    margin-top: 8.5vh !important;
}

.ant-picker, .ant-picker-focused {
    border-color: $gray;
    box-shadow: none !important;
}

.add-products-list-item {
    cursor: pointer;
    margin-bottom: 1rem;

    &:hover {
        background-color: whitesmoke;
    }
}

.client-icon-button {
    outline: none;
    border: none;
    background: none;
    transform: scale(1.4);
    //width: 2rem;
    //height: 2rem;
    //svg {
    //    width: 2rem;
    //    height: 2rem;
    //}
}

.list-menu {
    padding: 0;
    margin-top: 4rem;
    list-style: none;
    font-size: 1.8rem;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 7rem;

        span {
            display: flex;
            align-items: center;
        }

        &:not(:last-of-type) {
            border-bottom: .1rem solid rgba(black, .1);
        }

        &:only-child {
            border-bottom: .1rem solid rgba(black, .1);
        }

        &:hover {
            color: $primary;
            cursor: pointer;
            svg {
                path, circle, rect, ellipse {
                    stroke: $primary;
                }
            }
        }
    }
}

.scroll-to {
    box-sizing: border-box;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.react-horizontal-scrolling-menu--item:first-child {
    margin-left: 1.6rem;
}

.page-header-row {
    display: flex;
    justify-content: space-between;
    margin: 2.4rem 0;

    @include tablet{
        margin-top: 0;
        flex-direction: column;

        &>*:first-child {
            margin-bottom: 2rem;
        }

        &>*:nth-child(2) {
            display: flex;
            flex-direction: column;

            button {
                margin-top: 1rem;
            }
        }
    }
}

.page-search-row {
    display: flex;
    justify-content: space-between;
    margin: 2.4rem 0;
    &>div:first-child {
        width: 40rem;

        @include tablet {
            width: 100%;
        }
    }
}

.react-simple-image-viewer__close {
    opacity: .8 !important;
}

.submenus-scroll-container {
    scroll-behavior: auto !important;
}

.font-21 {
    font-size: 2.1rem;
}

.font-20 {
    font-size: 2rem;
}

.font-17 {
    font-size: 1.7rem;
}
