@import "../../../styles/utils";

.menuUnavailable {
  width: 90vw;
  background-color: white;
  border-radius: 2rem;
  padding: 3rem 6rem;
  text-align: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.2), inset 0px 0px 34px rgba(0, 0, 0, 0.1);

  img {
    width: 30%;
    margin: auto;
  }

  p {
    margin-top: 2rem;
    color: $text-secondary;
    font-size: 2rem;
    line-height: 2.6rem;
  }
}

@keyframes appear-animation {
  from {transform: scale(0) translate(-50%, -50%)}
  to {transform: scale(1) translate(-50%, -50%)}
}
