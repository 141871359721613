.product-rating-stars {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.4rem;

  .product-rating-title {
    font-size: 20px;
    font-weight: bold;
  }

  .text-tertiary {
    flex-basis: 100%;
  }

  .product-rating-count {
    color: #747474;
    font-weight: bold;
  }

  .stars {
    margin-left: 12px;

    .star, .star-half, .star-empty {
      height: 14px;
      width: 14px !important;
    }
  }
}
