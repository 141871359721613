@import "../../../styles/utils";

.filledStar {
  path {
    stroke: #fcb001;
    fill: #fcb001;
  }
}

.emptyStar {
  path {
    stroke: $text-quaternary;
    fill: $text-quaternary;
  }
}

.stars {
  display: flex;
  padding-left: 1rem;
}

.star {
  transform: scale(2);
  margin-right: 3rem;
}
