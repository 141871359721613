.businessHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 8rem;
    padding: 1.6rem 3rem;

    button {
        outline: none;
        border: none;
        background: none;
        transform: scale(1.4);
    }
}

.businessLogo {
    max-height: 5rem;
    border-radius: 1.2rem;
}

.businessHeaderActions {
    display: flex;
    align-items: center;
    button {
        margin-left: 2rem;
    }
}

.homeIcon {
    height: 2.6rem;
    width: 2.6rem;
    margin-left: -4px;
}






