@import "../../styles/utils";

.checkbox {
  width: 2.2rem;
  height: 2.2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2.2rem;
  background-color: $gray-light;

  svg {
    transform: scale(0.7);
    path {
      stroke: $text-secondary;
    }
  }
}
