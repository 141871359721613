@import "../../../styles/utils";

#header {
  display: flex;
  justify-content: space-between;

  height: 65px;
  width: 100%;
  align-items: center;
  padding: 4px 70px;
  background-color: $primary;

  .language {
    color: white !important;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: bolder;
    cursor: pointer;
  }

  .login-button {
    background-color: white;
    outline: none;
    border: none;
    color: $primary;
    border-radius: 15px;
    padding: 4px 20px;
    font-weight: bold;
  }

  img {
    height: 55px;
  }

  .desktop-nav {
    ul {
      list-style: none;
      display: flex;
      margin: 0;
      cursor: pointer;
      align-items: center;

      li {


        &:not(:last-child) {
          margin-right: 20px;
        }

        a {
          color: white !important;
          font-size: 12px;
          letter-spacing: 1px;
          font-weight: bold;

          &:hover {
            color: white !important;
            text-decoration: none;
          }
        }

      }
    }
  }

  .mobile-nav {
    background-color: $primary;
    //width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
    width: 0;
    transition: all .3s linear;
    overflow: hidden;

    &.isOpen {
      width: 100%;

      ul {
        opacity: 1;
        transition: all .5s linear;
      }
    }


    ul {
      transition: all .5s linear;
      opacity: 0;
      margin-top: 30px;
      list-style: none;

      li {
        margin-bottom: 20px;
        a {
          color: white !important;
          font-size: 16px;
          letter-spacing: 1px;
          font-weight: bold;

          &:hover {
            color: white !important;
            text-decoration: none;
          }
        }
      }
    }
  }


  .nav-bars {
    transform: scale(1.7);

    @media only screen and (min-width: 740px) {
      display: none;
    }

  }

  .nav-close {
    position: absolute;
    top: 40px;
    right: 40px;
    transform: scale(2.2);
  }


  @media only screen and (max-width: 740px) {
    padding: 4px 25px;

    .language {
      margin-left: 8px;
      font-size: 18px;
    }

    img {
      height: 42px;
    }
    .desktop-nav {
      display: none;
    }
  }

}
