.clientModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;

  padding: 3rem;
}

.clientModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  overflow-y: scroll;
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.clientModalButton {
  position: absolute;
  top: 3rem;
  right: 3rem;

  outline: none;
  border: none;
  background: none;
}
