@import "../../../styles/utils";

.statistic {
    height: 18rem;
    padding: 2.5rem;

    border-radius: 2rem;
    background: #FFFFFF;

    margin-bottom: 1rem;
}

.statisticIcon {
    padding: 1.5rem;

    width: 5.6rem;
    height: 5.6rem;

    border-radius: 1.5rem;
    background: rgba($primary, 0.05);
    box-shadow: 0 1rem 2.5rem rgba(2, 188, 77, 0.1);

    svg {
        path {
            stroke: $primary;
        }
    }
}

.statisticSubtitle {
    margin-top: 2.4rem;

    font-size: 1.6rem;
    line-height: 2rem;
    color: $text-secondary;
    letter-spacing: -0.024rem;
}

.statisticTitle {
    margin-top: 1rem;

    font-weight: 500;
    font-size: 2.6rem;
    line-height: 2rem;
    color: $text-secondary;
    letter-spacing: -0.024rem;
}
