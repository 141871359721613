.menusContainer {
  padding: 0 1.6rem;
}

.title {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.5rem;
}
