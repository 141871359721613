@import "../../../styles/utils";

.header {
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

.actions {
  display: flex;

  button {
    flex: 1;
    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}

.description {
  color: $text-tertiary;
  font-size: 1.5rem;
}

.total {
  color: $primary;
  font-size: 2.4rem;
}
