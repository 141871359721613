.postCard {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 2rem;
    background-color: white;

    button {
        margin-left: auto;
    }
}

.postCardImage {
    width: 5rem;
    height: 4.8rem;
    max-width: 5rem;
    max-height: 4.8rem;

    margin-right: 1.8rem;
    border-radius: 1.5rem;
    background-size: cover;
    background-position: center;
}

.postCardTitle {
    font-size: 1.8rem;
}



