.banner-card {
    position: fixed;
    bottom: 90px;
    left: 25px;
    right: 25px;
    //width: 100%;
    height: 120px;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
    box-shadow: 0px 6px 16px rgba(227, 236, 255, 1);
}

.banner-card-video-container {
    position: relative;
    width: 100%;
    height: 120px;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
    box-shadow: 0px 6px 16px rgba(227, 236, 255, 1);

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.banner-card-video {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}
